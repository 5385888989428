exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-admin-eng-tsx": () => import("./../../../src/pages/admin/eng.tsx" /* webpackChunkName: "component---src-pages-admin-eng-tsx" */),
  "component---src-pages-blog-swe-tsx": () => import("./../../../src/pages/blog/swe.tsx" /* webpackChunkName: "component---src-pages-blog-swe-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-swe-tsx": () => import("./../../../src/pages/swe.tsx" /* webpackChunkName: "component---src-pages-swe-tsx" */),
  "component---src-templates-blog-tsx": () => import("./../../../src/templates/Blog.tsx" /* webpackChunkName: "component---src-templates-blog-tsx" */),
  "component---src-templates-post-tsx": () => import("./../../../src/templates/Post.tsx" /* webpackChunkName: "component---src-templates-post-tsx" */)
}

